<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar :title="headline" :buttons="toolbarButtons" @onButton="onToolbarButton"></app-view-toolbar>
    </v-container>
    <v-container class="py-2 px-6">
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.items"
        :items-per-page="itemsPerPage"
        no-data-text="Inga kunder finns upplagda"
        no-results-text="Inga kunder hittades"
        :page.sync="page"
        :search="search"
        @click:row="openCustomer"
        @page-count="pageCount = $event"
        hide-default-footer
        class="click-table"
        locale="sv-SE"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Sök kund"
            class="mx-4"
          ></v-text-field>
        </template>
      </v-data-table>
      <div
        v-if="tableData.items.length > itemsPerPage"
        class="text-center pt-2"
      >
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import { onMounted, computed, ref, reactive } from '@vue/composition-api'
import useCore from '@/helpers/core'
import ViewToolbar from '@/components/ViewToolbar.vue'

export default {
  name: 'CustomerAll',
  components: {
    'app-view-toolbar': ViewToolbar,
  },
  setup() {
    const { callApi } = useCore();

    // Vue 2
    const router = require('@/router/router').default;

    const headline = 'Kunder';
    const dataLoaded = ref(false);
    const tableData = reactive({
      headers: [],
      items: [],
    });

    const search = ref('');

    const onData = (referer, response) => {
      console.log('onData', response);
      tableData.headers = response.tableData.headers;
      tableData.items = response.tableData.items;
      dataLoaded.value = true;
    };

    const onToolbarButton = button => {
      switch (button.label) {
        case 'new':
          openCustomerView();
        break;
      }
    }

    const openCustomer = cst => {
      console.log('openCustomer', cst);
      openCustomerView(cst.cst_id);
    }

    const openCustomerView = (id = 0) => {
      router.push({ name: 'customer', params: { id } });
    }

    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: '/cms/customers',
      })
        .then((apiResponse) => {
          onData('mounted', apiResponse);
        })
        .catch((err) => {
          return err;
        });
    };

    const toolbarButtons = computed(() => {
      const buttons = [];
      if (dataLoaded.value) {
        buttons.push({
          label: 'new',
          icon: 'plus',
          name: 'Ny kund',
        });
      }
      // console.log('computed toolbarButtons', buttons);
      return buttons;
    });

    onMounted(doOnMounted);

    return {
      headline,
      toolbarButtons,
      onToolbarButton,
      dataLoaded,
      tableData,
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      search,
      openCustomer,
    }
  },
};
</script>

<style>

</style>